<template>
  <div>
    <CyDataTableYdApi
      id="cy-pending-invites-table"
      ref="cyDataTable"
      :fetch-available="dataTableFetchParams"
      :headers="$static.headers"
      :searchable-fields="$static.searchableFields"
      :filters="$static.filters"
      :sorts="$static.sorts"
      :bulk="hasBulkModeEnabled"
      key-field="id">
      <template #table-cmp-header-actions="{ selected }">
        <template v-if="!_.isEmpty(selected)">
          <CyButton
            v-has-rights-to="'RemoveOrgMember'"
            theme="error"
            variant="tertiary"
            icon="delete"
            @click="openDeleteModal(selected)">
            {{ $tc('deleteInvitesBtn', selected.length, { nb: selected.length }) }}
          </CyButton>
          <CyButton
            icon="send"
            @click="openResendModal(selected)">
            {{ $tc('resendInvitesBtn', selected.length, { nb: selected.length }) }}
          </CyButton>
        </template>
        <CyButton
          v-else
          v-has-rights-to="'InviteUserToOrgMember'"
          icon="add"
          :disabled="isMaxMembersReached"
          :to="{ name: 'memberInvite' }">
          {{ $t('routes.membersInvite') }}
        </CyButton>

        <CyModal
          v-if="show.deleteModal"
          :header-title="$tc('confirmDeleteTitle', toDelete.length)"
          :action-btn-func="onDelete"
          :cancel-btn-func="() => $toggle.show.deleteModal(false)"
          :action-btn-text="$tc('deleteInvitesBtn', toDelete.length, { nb: toDelete.length })"
          :loading="resendingOrDeleting"
          small
          modal-type="delete">
          <p>
            {{ $t('forms.cannotBeUndone') }}
            <span v-html="$tc('areYouSure', toDelete.length)"/>
          </p>
          <ul class="items-to-delete">
            <li
              v-for="{ id,invitation_email: email } of toDelete"
              :key="id">
              {{ email }}
            </li>
          </ul>
        </CyModal>

        <CyModal
          v-if="show.resendModal"
          :header-title="$tc('confirmResendTitle', toResend.length)"
          :action-btn-func="onResend"
          :cancel-btn-func="() => $toggle.show.resendModal(false)"
          :action-btn-text="$tc('resendInvitesBtn', toResend.length, { nb: toResend.length })"
          :loading="resendingOrDeleting"
          small>
          <ul class="items-to-delete">
            <li
              v-for="{ id, invitation_email: email } of toResend"
              :key="id">
              {{ email }}
            </li>
          </ul>
        </CyModal>
      </template>

      <template #table-cmp-body-row="{ props: { item } }">
        <td>{{ item.invitation_email }}</td>
        <td>{{ item.role.name }}</td>
        <td>
          <CyButton
            v-if="item.invited_by"
            variant="tertiary"
            theme="grey"
            member-link
            class="px-0"
            :to="{ name: 'member', params: { id: item.invited_by.id } }">
            <CyMember :member="item.invited_by"/>
          </CyButton>
        </td>
        <td>{{ $date.$formatTimeAgo(item.resent_at || item.created_at) }}</td>
        <td>
          <CyTag :variant="_.get($static.statusColors, item.invitation_state, 'default')">
            {{ item.invitation_state }}
          </CyTag>
        </td>
        <td class="action-btns d-flex align-center">
          <CyButton
            variant="tertiary"
            icon="send"
            icon-only
            @click="openResendModal([item])"/>
          <CyButton
            v-has-rights-to="'RemoveOrgMember'"
            data-cy="delete-invitation"
            theme="error"
            variant="tertiary"
            icon="delete"
            icon-only
            @click="openDeleteModal([item])"/>
        </td>
      </template>
    </CyDataTableYdApi>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import CyDataTableYdApi from '@/components/data-table-yd-api.vue'
import { constructBreadcrumb } from '@/utils/helpers'

export default {
  name: 'CyPagePendingInvites',
  components: {
    CyDataTableYdApi,
  },
  breadcrumb () {
    return constructBreadcrumb(this.$options.name, this.$t('routes.pendingInvites'))
  },
  header () {
    return {
      title: this.$t('title'),
    }
  },
  data: () => ({
    resendingOrDeleting: false,
    show: {
      deleteModal: false,
      resendModal: false,
    },
    toDelete: [],
    toResend: [],
  }),
  computed: {
    ...mapState('organization', {
      invites: (state) => state.available.invites,
    }),
    ...mapGetters('organization/licence', [
      'isMaxMembersReached',
    ]),
    ...mapGetters('layout', [
      'getDataTableFilters',
    ]),
    $static () {
      return {
        headers: [
          {
            text: this.$t('forms.fieldEmail'),
            value: 'invitation_email',
            align: 'left',
          },
          {
            text: this.$t('forms.role'),
            align: 'left',
            value: 'role',
            sort: (a, b) => {
              return a.name.localeCompare(b.name)
            },
          },
          {
            text: this.$t('invitedBy'),
            align: 'left',
            value: 'invited_by',
            sort: (a, b) => {
              return `${a.given_name}${a.family_name}`.localeCompare(`${b.given_name}${b.family_name}`)
            },
          },
          {
            text: this.$t('lastSent'),
            align: 'left',
            value: 'resent_at',
          },
          {
            text: this.$t('forms.status'),
            align: 'left',
            value: 'state',
          },
          {
            value: 'actions',
          },
        ],
        searchableFields: [
          {
            name: 'invitation_email',
            label: this.$t('forms.fieldEmail'),
          },
        ],
        statusColors: {
          accepted: 'success',
          pending: 'warning',
          declined: 'error',
        },
        filters: [
          {
            queryParams: ['invitation_state'],
            search: false,
            type: 'select',
            label: this.$t('forms.status'),
            items: [
              ...['pending', 'declined'].map((severity) => ({
                title: `${this.$t(severity)}`,
                key: 'invitation_state',
                value: severity,
              })),
            ],
          },
        ],
        sorts: [
          { sortBy: ['created_at'], sortDesc: [false] },
          { sortBy: ['created_at'], sortDesc: [true] },
          { sortBy: ['email'], sortDesc: [false] },
          { sortBy: ['email'], sortDesc: [true] },
        ],
        defaultFilters: {
          'invitation_state[in]': 'pending,declined',
        },
      }
    },
    dataTableFetchParams () {
      const invitationStateFilter = _.get(this.getDataTableFilters(this.$route.name), 'invitation_state[in]', null)
      const extraParams = invitationStateFilter ? [] : [this.$static.defaultFilters]
      return { keyPath: 'members', extraParams }
    },
    hasBulkModeEnabled () {
      const canDelete = this.$cycloid.permissions.canDisplay('RemoveOrgMember')
      const canResend = true // this.$cycloid.permissions.canDisplay('ResendInvitation') // TODO: FE#6374 Implement permissions to resend
      return !_.isEmpty(this.invites) && _.some([canDelete, canResend])
    },
  },
  methods: {
    ...mapActions('organization', [
      'BULK_DELETE',
      'RESEND_INVITES',
    ]),
    openDeleteModal (toDelete) {
      this.toDelete = toDelete
      this.$toggle.show.deleteModal(true)
    },
    openResendModal (toResend) {
      this.toResend = toResend
      this.$toggle.show.resendModal(true)
    },
    async onDelete () {
      const { toDelete } = this
      this.$toggle.resendingOrDeleting(true)
      await this.BULK_DELETE({ keyPath: 'invites', toDelete })
      this.$toggle.resendingOrDeleting(false)
      this.$toggle.show.deleteModal(false)
      this.toDelete = []
      this.$refs.cyDataTable.retrieveItems({ clearErrors: false, clearSelected: true })
    },
    async onResend () {
      const { toResend } = this
      this.$toggle.resendingOrDeleting(true)
      await this.RESEND_INVITES({ invites: toResend })
      this.$toggle.resendingOrDeleting(false)
      this.$toggle.show.resendModal(false)
      this.toResend = []
      this.$refs.cyDataTable.retrieveItems({ clearErrors: false, clearSelected: true })
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.pendingInvites',
        accepted: 'Accepted',
        areYouSure: 'Are you really sure you want to delete the following invitation? | Are you really sure you want to delete the following invitations?',
        confirmDeleteTitle: '@:forms.btnDelete invitation | @:forms.btnDelete invitations',
        confirmResendTitle: 'Resend invitation | Resend invitations',
        declined: 'Declined',
        deleteInvitesBtn: '@:forms.btnDelete invitation | @:forms.btnDelete {nb} invitations',
        invitedBy: 'Invited by',
        lastSent: 'Last sent',
        pending: 'Pending',
        resendInvitesBtn: 'Resend invitation | Resend {nb} invitations',
      },
      es: {
        title: '@:routes.pendingInvites',
        accepted: 'Aceptada',
        areYouSure: '¿Está realmente seguro de que desea eliminar la siguiente invitación? | ¿Está realmente seguro de que desea eliminar las siguientes invitaciones?',
        confirmDeleteTitle: '@:forms.btnDelete la invitación | @:forms.btnDelete invitaciones',
        confirmResendTitle: 'Reenviar la invitación | Reenviar invitaciones',
        declined: 'Rechazada',
        deleteInvitesBtn: '@:forms.btnDelete la invitación | @:forms.btnDelete {nb} invitaciones',
        invitedBy: 'Invitado por',
        lastSent: 'Último enviado',
        pending: 'Pendiente',
        resendInvitesBtn: 'Reenviar la invitación | Reenviar {nb} invitaciones',
      },
      fr: {
        title: '@:routes.pendingInvites',
        accepted: 'Acceptée',
        areYouSure: `Êtes-vous vraiment sûr de vouloir supprimer l'invitation suivante? | Êtes-vous vraiment sûr de vouloir supprimer les invitations suivantes?`,
        confirmDeleteTitle: `@:forms.btnDelete l'invitation | @:forms.btnDelete des invitations`,
        confirmResendTitle: 'Renvoyer une invitation | Renvoyer des invitations',
        declined: 'Refusée',
        deleteInvitesBtn: `@:forms.btnDelete l'invitation | @:forms.btnDelete {nb} invitations`,
        invitedBy: 'Invité par',
        lastSent: 'Dernier envoyé',
        pending: 'En cours',
        resendInvitesBtn: 'Renvoyer une invitation | Renvoyer {nb} invitations',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
  .pending-invites {
    &__title {
      color: cy-get-color("primary");
      font-size: $font-size-lg;
      font-weight: $font-weight-bolder;
    }
  }

  .action-btns {
    .v-btn.v-btn.cy-btn.cy-btn__variant--tertiary:not(:active, :hover, :focus) {
      color: cy-get-color("grey", "dark-1");
    }
  }
</style>
